.hint{
  font-size: .8em;
}
.stream{
  padding:1em;
}
.streamSingle{
  list-style-type: none;
  padding-left: 1em;
}
.streamSingle-item{
  padding:1em;
  padding-left: 0px;
}
.streamSingle-item a{
font-family: "Droid Serif", serif!important;
}
.streamSingle-item .streamSingle-item-content{

}

.streamSingle-item-content{
  width:100%;

}
