.main-container .form-group iframe{
  width:100%;
  height:350px;
}

.form-message{
  padding:8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  font-weight: 800;
}

.form-message.error{
  background-color: #FFCCCC;
}
.form-message.success{
  background-color: #CCFFCC;
}
