.quad .widget-title{
  border-bottom:5px solid #000;line-height:1.5em;position:relative;
}
.article-card-title{
  padding-left: 7px;
}
.article-card-title h4{
  margin-bottom:7px;
}
.article-card-title h5{
  margin-top:3px;
  margin-bottom:7px;
}
