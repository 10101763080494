.article-story .article-img img{
  width:100%;
}

.article-story .article-body{
  padding-top:7px;
}

.article-story  strong, .article-story  em, .article-story  b{
  font-family: "Droid Serif", serif;
}
