.navbar-contact{
  padding-left: 10px;
}
.navbar-contact.top{
  margin-top:10px;
  margin-bottom:0px;
  line-height: 2em;
}
.navbar-contact.bottom{
  margin-top:6px;
}
.navbar-contact, .navbar-contact li{
  display:inline-block;
  margin-right:5px;
}
.navbar-link.link-label{
  margin-right: 15px;
  width:40px;
}
.navbar-link.link-label, .navbar-link.link-label strong{
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.navbar-contact li a{
  background-color: #000;
  padding:5px;
  color:#fff;

  box-shadow: 0px 3px 5px grey;

  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
