.css-viou3o-MuiAutocomplete-root{
  width:100%!important;
}

.css-viou3o-MuiAutocomplete-root label{
  color:#fff;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height:1.2em!important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  line-height:1.2em!important;
}
