.concert-item-title h5{
  margin-bottom:0px;
  padding-bottom: 0px;
}
.concert-item-title h5{
  box-shadow: 0px 0px 5px grey;
  padding:0.2em;
  margin:0.5em;
  margin-top:0em;
  padding-top:0em;
}
