.cover .widget-title{

  border-bottom:5px solid #000;line-height:1.5em;position:relative;
}

.cover .cover-img{
  width:100%;
}

.cover .cover-img img{
  width:100%;max-width: 100%;
}
