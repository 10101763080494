.trending h1.widget-title{
  border-bottom:5px solid #000;line-height:1.5em;position:relative;
}

.trending h1.widget-title a.button{
  position:absolute;right:0px;
}

.trending-container{
  margin-top:-8px;
}
.article-card.solo.top{
  margin-top:16px;
}
.article-card.solo{
  margin-bottom:10px;
}
