/* button.MuiButtonBase-root.MuiIconButton-root svg{
  margin-top:-26px;
} */

.MuiFormControl-root, .MuiFormControl-marginNormal{
  margin-top:0px!important;
}

label.MuiInputLabel-formControl{
color:#fff!important;
}
/* .MuiPickersCalendar-transitionContainer{
  min-height: 190px!important;
} */
.MuiInputBase-input, button.MuiButtonBase-root{
  color:#fff!important;
}
.MuiTypography-colorInherit{
  color: #616161!important;
}
.MuiIconButton-root.MuiPickersDay-daySelected{
  background-color: #000!important;
  border-radius: 0%;
}

.MuiIconButton-root:hover{
  border-radius: 0%!important;
  background-color: #666!important;
}

.MuiIconButton-root:hover .MuiTypography-colorInherit{
  color:#fff!important;
  font-weight: 800!important;
}

.MuiIconButton-root.MuiPickersDay-daySelected .MuiTypography-colorInherit{
  color:#fff!important;
  font-weight:800;
}


.concert-selector {
  transition: opacity 0.5s ease-in;
  background: #222;
  color: white;
  opacity: 1;
  display: inline-block;
  font-family: Arial;
}

.concerts-filters a{
  z-index: 20!important;

}

.concerts-selectors{
  position:relative;
  margin-right: 8px;
}

.concerts-selectors .concert-selector{
  width:100%;
  position:absolute;
  top:40px;
  left:0px;
  height:60px;

  z-index: 2;
}

.concerts-selectors .concert-selector .wrap{
  position:relative;
}

.concerts-selectors .concert-selector input{
  z-index: 20;
margin-left: 16px;
}
.concerts-selectors .concert-selector .component{
  width:44%;
  display: inline-block;
  color:#fff;
  padding-left: 16px;

}
.selector-go{
  width:10%;
  display: inline-block;
  text-align: right;
}
.selector-go input{
  background-color: #000;
  color:#fff;
  font-weight: 800;
  z-index: 20;
  pointer-events: all;
  cursor: pointer;
  position:absolute;
  right:12px;
  top:12px;
}
.hide {
  opacity: 0;
  pointer-events: none;
}

.hide a, .hide input, .hide button{
  pointer-events: none;
}

.city-selector{

}

.artist-selector{

}
