.concerts-selectors .concert-selector input{
margin-left: 16px;
}
#region-selector label{
  margin-right:16px;
  width:100%;
  display: inline-block;
  padding-top:12px;
  text-align: right;

}
#region-selector a{
  margin-left: 32px;
  background-color: #000;
  color:#fff;
  padding:6px;
  border:1px solid #fff;
  pointer-events: all;
}
#fest-artist-selector .component{
  margin-right:16px;
  width:90%;
  text-align: right;
}
#fest-artist-selector .css-viou3o-MuiAutocomplete-root{
  width:300px!important;
  float:right;
  padding-top: 8px;
}
.wrap.regions{
  padding-top: 12px;
  padding-right: 12px;
}
