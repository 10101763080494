@media screen and (min-width: 651px) {
  .brand{
    background:url(https://exclaim.ca/assets/images/ExclaimLogoW.png);
    max-width:180px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:168px 40px;
    display:block;
    width:168px;
    height:40px;
  }
  .brand-item{
    margin-left: -10px;
    margin-top:-5px;
  }
  .drawer-list{
    position: -webkit-stiky;
    position: sticky!important;
    top: 10px;
  }
}
@media screen and (max-width: 650px) {
  .brand-item{
    display:none!important;
  }
}
