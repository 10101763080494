.brand{
  background:url(https://exclaim.ca/assets/images/ExclaimLogoW.png);
  max-width:180px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size:168px 40px;
  display:block;
  width:168px;
  height:40px;
}
.brand-item{
  margin-left: -10px;
  margin-top:-10px;
}


.header {
  font-family: "Montserrat", sans-serif;
  background: #000;
  color: #fff;
  font-size: 2em;
  text-align: center;
  z-index: 10;
  height: 43px;
}
.header a {
  text-decoration: none;
  color: #fff;
}
.header .brand {
  background: url("https://exclaim.ca/assets/images/ExclaimLogoW.png");
  background-position: center center;
  background-repeat: no-repeat;
  max-height: 30px;
  background-size: 100%;
  text-indent: -999em;
  width: 180px;
  display: block;
  height: auto;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 2px;
  margin-left: 0px;
  float: left;
}

.search {
  float: right;
  padding-top: 3px;
  padding-right: 8px;
  background-color: #000;
}
.search i {
  color: #fff;
}

#hamburger {
  float: left;
}

.header-list {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px;
  padding-top: 0px;
  text-align: center;
  height: 1em;
}
.header-list li {
  display: inline-block;
  font-size: 0.5em;
  padding-top: 0px;
  vertical-align: super;
}

.hamburger_wrapper {
  position: relative;
  padding: 5px;
  z-index: 10;
  height: 32px;
}

#personalized_amp_container {
  margin-top: 10px;
}

#hamburger {
  width: 29px;
  height: 25px;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-left: 19px;
  margin-right: 10px;
}
#hamburger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #005af0;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 4px;
  transform-origin: left center;
}

#hamburger span:nth-child(2) {
  top: 14px;
  transform-origin: left center;
}

#hamburger span:nth-child(3) {
  top: 24px;
  transform-origin: left center;
}

#hamburger.close span:nth-child(1) {
  transform: rotate(45deg);
}

#hamburger.close span:nth-child(2) {
  width: 0%;
  opacity: 0;
  transition: 0.1s;
}

#hamburger.close span:nth-child(3) {
  transform: rotate(-45deg);
}
#nav-menu {
  position: relative;
  transform: translateY(-100vw);
  /* display:none; */
  height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 0;
  transition: transform 0.3s ease, opacity ease 0.2s;
  float: left;
  width: 100%;
}

#nav-menu.now-active {
  transform: translateY(0);
  transition: transform 0.3s ease, opacity ease 0.2s, height 0.5s ease;
  opacity: 1;
  /* display:block; */
  height: auto;
  background-color: #eaeaea;
  background-color: #dcecf9;
  background-color: #000;
  padding-top: 0.5em;
}

.nav-list {
  padding: 10px;
  list-style-type: none;
  font-size: 0.5em;
  margin-top: 0px;
  line-height: 1.4em;
}
.nav-list li a {
  color: #000;
  color: #fff;
  text-transform: uppercase;
}
.article,
.stream,
.widget {
  border: 0px;
  box-shadow: none;
}

@media screen and (min-width: 900px) {
  .header{
    display:none!important;
  }
}
