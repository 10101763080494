/* .article-item{
  width:100%;
  position:relative;
  min-height:60px;
}
.article-item-title{
  float:left;
}
.article-item-img{
  position:absolute;
  right:0px;
  top:0px;
} */
.article-item{

  display: grid;
  /*245 for drawer*/
  /*190 for drawer mobile */
  grid-template-columns: 60px 1fr ;

}

.article-item-title h4{
  margin-bottom:0px;
}
.article-item-title h5{
  margin-top:0px;
}

.article-item-img, .article-item-img img{
  height: 60px;
  width: 60px;
  max-height:60px;
}

.article-item-img img{
/* float:right; */
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
}
